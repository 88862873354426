import React from "react";
import Navbar from "../Navbar/navbar";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import textbookicon from "../Images/textbook.jpeg"
import calendaricon from "../Images/calendars.jpeg"
import cartonsicon from "../Images/cartonsicon.jpeg"
import "./homepage.css"
import offsetimg from "../Images/offsetimg.png"
import laminateimg from "../Images/laminateimgs.png"
import pastingimg from "../Images/pastingimg.png"
import homeimg from "../Images/homeimage.jpeg"
import cartonimg from "../Images/cartons.jpeg"
import booksimg from "../Images/books.jpeg"
import othersimg from "../Images/others.jpeg"
import Contact from "../Contact/contact";
// import hometoplogo from "../Images/hometoplogo.png"


const HomePage = () => {

    return (
        <>
            <Navbar />
            <div className="main-home">
                <div className="home-img">
                    <img src={homeimg} alt="main-img" className="main-img-align" />
                    <div class="text-centered">Welcome to VSR Paper and Packaging Limited, your reliable partner in sustainable printing and packaging solutions.</div>
                </div>
                {/* <div className="sliding-images">
                <Carousel autoPlay={true} infiniteLoop={true}>
                    <div>
                        <img src={textbookicon} className="carousel-img" alt="books" />
                        <p className="legend">Books</p>
                    </div>
                    <div>
                        <img src={calendaricon} className="carousel-img" alt="calendars" />
                        <p className="legend">Calendar</p>
                    </div>
                    <div>
                        <img src={cartonsicon} className="carousel-img" alt="cartons" />
                        <p className="legend">Cartons</p>
                    </div>
                </Carousel>
            </div> */}
                <div className="quality-text">Our Quality Offerings</div>
                <div className="quality-group-boxes">
                    <div className="quality-box">
                        <img src={cartonimg} alt="cartons" className="quality-boximg" />
                        <div>
                            <p className="quality-title">Cartons</p>
                            <p className="quality-description">We provide foundational packaging solutions,  combining affordability and quality. Ideal for  businesses looking for essential packaging  supplies without compromising quality.</p>
                        </div>
                    </div>
                    <div className="quality-box">
                        <img src={booksimg} alt="books" className="quality-boximg" />
                        <p className="quality-title">Books</p>
                        <p className="quality-description">Our wide range of books , includes any type of printed books  and note books</p>
                    </div>
                    <div className="quality-box">
                        <img src={othersimg} alt="others" className="quality-boximg" />
                        <p className="quality-title">Others</p>
                        <p className="quality-description">Our other products included, leaflets, calendars, diaries  and many other supplies without compromising quality.</p>
                    </div>
                </div>
                <br/>
                <Contact/>
                {/* <div className="aboutus">
                <div className="paragraph">
                    <div className="left-para">
                        VSR Paper and Packaging Ltd. (VSRPPL) is a Bangalore-based manufacturer of paper and
                        paperboard products. Printing and packaging materials are manufactured at Hyderabad in
                        premises extending up to 30,000sqft. The company has established itself as a preferred
                        vendor/supplier of a wide range of printing, packaging, Books and other paper products
                        in leading companies and traders in Andhra Pradesh and Telangana State.
                    </div>
                    <div className="right-para">
                        <img src={offsetimg} alt="offsetimg" className="img-para" />
                    </div>
                </div>
                <div className="paragraph">
                    <div className="left-para">
                        <img src={laminateimg} alt="laminateimg" className="img-para" />
                    </div>
                    <div className="right-para" style={{ marginTop: "50px" }}>
                        The company started its core manufacturing activity in 2020 with the main objective of manufacturing
                        and selling all kinds of paper and paperboard packaging items for <span style={{ fontWeight: 700 }}>Pharmaceuticals, food & beverages
                            and home & personal care</span>. Presently we are supplying all verities of Mono cartons and E-flute cartons.
                    </div>
                </div>
                <div className="paragraph">
                    <div className="left-para" style={{ marginTop: "90px" }}>
                        Gradually the company has entered into  manufacturing books and other stationery items.
                        Our wide range of products also includes <span style={{ fontWeight: 700 }}>copier paper, exam papers, letterheads, leaflets,
                            calendars, diaries and all types of Notebooks and printed books.</span>
                    </div>
                    <div className="right-para">
                        <img src={pastingimg} alt="pastingimg" className="img-para" />

                    </div>
                </div>
            </div> */}
                
            </div>
        </>
    );
}
export default HomePage;
