import React from "react";
import Navbar from "../Navbar/navbar";
import "./products.css"
import cartonimg from "../Images/cartons.jpeg"
import booksimg from "../Images/books.jpeg"
import othersimg from "../Images/others.jpeg"
import Contact from "../Contact/contact";


const Products = () => {

    return (
        <>
            <Navbar />
            <div className="main-home">
                <div className="products-text">Our Products</div>
                <div className="quality-group-boxes">
                    <div className="quality-box">
                        <img src={cartonimg} alt="cartons" className="quality-boximg" />
                        <div>
                            <p className="quality-title">Cartons</p>
                            <p className="quality-description">We provide foundational packaging solutions,  combining affordability and quality. Ideal for  businesses looking for essential packaging  supplies without compromising quality.</p>
                        </div>
                    </div>
                    <div className="quality-box">
                        <img src={booksimg} alt="books" className="quality-boximg" />
                        <p className="quality-title">Books</p>
                        <p className="quality-description">Our wide range of books , includes any type of printed books  and note books</p>
                    </div>
                    <div className="quality-box">
                        <img src={othersimg} alt="others" className="quality-boximg" />
                        <p className="quality-title">Others</p>
                        <p className="quality-description">Our other products included, leaflets, calendars, diaries  and many other supplies without compromising quality.</p>
                    </div>
                </div>
                <br/>
                <Contact/>
               
                
            </div>
        </>
    );
}
export default Products;
