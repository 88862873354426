// import logo from './logo.svg';
import './App.css';
// import { useState, useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/homepage"
import ContactPage from './Pages/contact';
import Machinery from './Pages/machinery';
import About from './Pages/about';
import Products from './Pages/products';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/about" element={<About/>} />
          <Route exact path="/machinery" element={<Machinery />} />
          <Route exact path="/contact" element={<ContactPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
