import React from "react";
import Navbar from "../Navbar/navbar";
import "./contact.css"
import contactimg from "../Images/contactimg.png"
import Contact from "../Contact/contact";

const ContactPage = () => {
    return (
        <>
            <Navbar />
            <br />
            <br />
            <br />
            <div className="contact-form">
                <div className="contact-text-left">
                    <div className="contact-text">Contact VSR Paper and Packaging</div>
                    <div className="contact-title">We invite you to reach out for any inquiries regarding our paper
                        and paperboard manufacturing. Expect timely responses regarding your needs and our services.
                        Please ensure you select a suitable method for your submissions.</div>
                    <br />
                    <div className="contact-email"> <a className="reach-color" target="_blank" rel="noreferrer" href="mailto:info@vsrppl.com">info@vsrppl.com</a></div>
                    <div className="contact-phone"><a className="reach-color" href="tel:+914042300206">+91 40 4230 0206</a> , <a className="reach-color" href="tel:+917674800206">+917674800206</a></div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="contact-details">
                        <div className="contact-text-left">
                            <div>First Name</div>
                            <input type="text" className="textfield" />
                        </div>
                        <br />
                        <br />
                        <div className="contact-text-left">
                            <div>Last Name</div>
                            <input type="text" className="textfield" />
                        </div>
                    </div>
                    <br />
                    <br />
                    <div>
                        <div>Email (required)</div>
                        <input type="text" className="textfieldemail" />
                    </div>
                    <br />
                    <br />
                    <div>
                        <div>Message (required)</div>
                        <textarea className="textfieldemail" rows="4" cols="60"></textarea>
                    </div>
                    <div className="contact-sendbtn">Send</div>
                    {/* <iframe title="vsrpplmaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.898205646904!2d78.4366051!3d17.4645856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb911e430101ad%3A0xf18c74ceee66c008!2sVSR%20Paper%20and%20Packaging%20Ltd.!5e0!3m2!1sen!2sin!4v1721223294492!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                </div>
                <br/>
                <div className="contact-img-right">
                    <img src={contactimg} alt="contactimg" className="contactpage-img" />
                </div>
            </div>
            <div className="contact-display">
                <Contact />
            </div>
        </>
    )
}

export default ContactPage;