import React from "react";
// import MainLogo from "../Images/mainlogo.png"
import "./contact.css";
import { useNavigate } from "react-router-dom";

const Contact = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="contact">
                <div className="contact-title">
                    VSR PAPER AND PACKAGING LIMITED
                </div>
                <br />
                <br />
                <div className="contact-tiles">
                    <div>
                        <div className="contact-text-left">
                            {/* <div className="contact-title">Feel Free to Contact Us</div> */}
                            <br />
                            <div className="contact-email"> <a className="reach-color" target="_blank" rel="noreferrer" href="mailto:info@vsrppl.com">info@vsrppl.com</a></div>
                            <br />
                            <div className="contact-phone"><a className="reach-color" href="tel:+914042300206">+9140&nbsp;4230&nbsp;0206</a></div>
                            <div className="contact-phone"><a className="reach-color" href="tel:+917674800206">+917674800206</a></div>
                        </div>
                    </div>
                    <br />
                    <div style={{ display: "flex" }}>
                        <div className="contact-tile-1">
                            <p className="contact-tile-title">Explore</p>
                            <p className="contact-tile-name" onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                })
                                navigate("/")
                            }}>Home</p>
                            <p className="contact-tile-name" onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                })
                                navigate("/about")
                            }}>About Us</p>
                            <p className="contact-tile-name" onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                })
                                navigate("/products")
                            }}>Products</p>
                            <p className="contact-tile-name" onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                })
                                navigate("/machinery")
                            }}>Machinery</p>
                            <p className="contact-tile-name" onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                })
                                navigate("/contact")
                            }}>Contact</p>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div className="contact-tile-2">
                            <p className="contact-tile-title">Contact</p>
                            <p className="contact-tile-name">Email</p>
                            <p className="contact-tile-name">Facebook</p>
                            <p className="contact-tile-name">Instagram</p>
                        </div>
                    </div>
                    <br />
                    <div>
                        <iframe title="vsrpplmaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.898205646904!2d78.4366051!3d17.4645856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb911e430101ad%3A0xf18c74ceee66c008!2sVSR%20Paper%20and%20Packaging%20Ltd.!5e0!3m2!1sen!2sin!4v1721223294492!5m2!1sen!2sin" className="contact-maps" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

            </div>
        </>
    );
}
export default Contact;
