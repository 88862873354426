import React, { useState } from "react";
import MainLogo from "../Images/mainlogo.png"
import "./navbar.css";
import { useNavigate } from "react-router-dom";

const Navbar = ({ color }) => {
    let [mobilenav, setmobilenav] = useState(false)
    const navigate = useNavigate();
    let link = window.location.href.split("/")[3]

    return (
        <>
            <div className="nav" style={{ color: color ? color : "black" }}>

                <div className="nav-title" >
                    <div className="nav-left-title" onClick={() => navigate("/")}>
                        <div>
                            <img src={MainLogo} alt="mainlogo" className="mainlogo-img" />
                        </div>
                        <div>
                            VSR PAPER AND PACKAGING LIMITED
                        </div>
                    </div>
                    <div className="group-bars" onClick={() => setmobilenav(true)}>
                        <hr className="hr-bars" />
                        <hr className="hr-bars" />
                    </div>
                </div>
                <div className="nav-content">
                    <p className="nav-button" style={{ textDecoration: link === "" ? "underline" : "" }} onClick={() => navigate("/")}>Home</p>
                    <p className="nav-button" style={{ textDecoration: link === "about" ? "underline" : "" }} onClick={() => navigate("/about")}>About</p>
                    <p className="nav-button" style={{ textDecoration: link === "products" ? "underline" : "" }} onClick={() => navigate("/products")}>Products</p>
                    <p className="nav-button" style={{ textDecoration: link === "machinery" ? "underline" : "" }} onClick={() => navigate("/machinery")}>Machinery</p>
                    <p className="nav-button" style={{ textDecoration: link === "contact" ? "underline" : "" }} onClick={() => navigate("/contact")}>Contact</p>
                </div>
                <div style={{ display: mobilenav ? "" : "none" }} className="nav-mobile-content">
                    <div className="nav-mobile-title">
                        <div>VSR PAPER AND PACKAGING LIMITED</div>
                        <div className="nav-mobile-close">
                            <div onClick={() => setmobilenav(false)}>&#10005;</div>
                        </div>
                    </div>
                    <div className="nav-mobile-navs">
                        <p className="nav-mobile-button" style={{ textDecoration: link === "" ? "underline" : "" }} onClick={() => navigate("/")}>Home</p>
                        <p className="nav-mobile-button" style={{ textDecoration: link === "about" ? "underline" : "" }} onClick={() => navigate("/about")}>About</p>
                        <p className="nav-mobile-button" style={{ textDecoration: link === "products" ? "underline" : "" }} onClick={() => navigate("/products")}>Products</p>
                        <p className="nav-mobile-button" style={{ textDecoration: link === "machinery" ? "underline" : "" }} onClick={() => navigate("/machinery")}>Machinery</p>
                        <p className="nav-mobile-button" style={{ textDecoration: link === "contact" ? "underline" : "" }} onClick={() => navigate("/contact")}>Contact</p>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </>
    );
}
export default Navbar;
